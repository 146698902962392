import { Link } from 'gatsby-theme-material-ui';
import React from 'react'; // eslint-disable-line no-unused-vars

import Layout from '../components/Layout';

import {
  List,
  Paragraph,
  Strong,
} from '../components/Texts';

const Home = () => (
  <Layout>
    <Paragraph sx={{ mt: 4 }}>
      <strong>Mak Corp</strong> propose des services d’analyse de données, de calcul par ordinateurs et de traitement de données spatiales.
    </Paragraph>

    <Paragraph>
      Mak Corp est spécialisée dans les problématiques des <Strong>équipes de recherche</Strong>.<br />
      Elle apporte ses compétences informatiques pour résoudre des problèmes que peuvent rencontrer des chercheurs et outiller leurs travaux.
    </Paragraph>

    <Paragraph>
      Mak Corp intervient également pour des <Strong>structures publiques</Strong> sur des problématiques de <Strong>gestion de l’environnement</Strong> ou d’<Strong>aménagement de territoires</Strong>.
    </Paragraph>

    <Paragraph>
      Mak Corp propose des <Link color="secondary" to="/services">services</Link> liés à l’ingénierie logicielle et se distingue par son <Link color="secondary" to="/expertises">expertise</Link>dans des domaines pointus.
    </Paragraph>

    <Paragraph sx={{ mb: 0 }}>
      Notre boîte à outils comprend en particulier&nbsp;:
    </Paragraph>
    <List>
      <List item>
        outils de l’éco-système Python<br />
        <em>(Numpy, Scipy, Pandas, Vaex, Dask, PyTorch, Keras, Tensorflow, Scikit…)</em>
      </List>
      <List item>bases de données spatiales&nbsp;: PostGIS, GeoMesa</List>
      <List item>cartographie interactive - visualisation&nbsp;: MapLibre, Leaflet, Mapbox GL JS</List>
      <List item>cartographie interactive - tuiles vectorielles&nbsp;: Makina Maps, OpenMapTiles, TileServer GL, Martin</List>
      <List item>interfaces d’administration et de saisie de données&nbsp;: Baserow, LCK, Grist, React Admin</List>
      <List item>méthodes et algorithmes d’intelligence artificielle, en particulier machine learning, deep learning, réseaux neuronaux</List>
    </List>

  </Layout>
);

export default Home;
